// .arrow-button:disabled {
//   opacity: 0.5 !important;
//   cursor: not-allowed !important;
// }

// .day.disabled {
//   opacity: 0.5 !important;
//   cursor: not-allowed !important;
// }

// .time-slot.disabled {
//   opacity: 0.5 !important;
//   cursor: not-allowed !important;
// }

// .time-slot.reserved {
//   background-color: #f8cbc9 !important;
//   cursor: not-allowed !important;
//   opacity: 0.7 !important;
// }

// .time-slot .unavailable {
//   background-color: #f9cc8a !important;
//   color: #999 !important;
//   cursor: not-allowed !important;
// }

// .time-slot .status-message {
//   font-size: 0.8em !important;
//   color: #888 !important;
// }

// .date-picker {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   background: #fff;
//   overflow: hidden;
//   max-width: 330px;
//   margin: auto;

//   .calendar {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     border: 1px solid #ccc;
//     border-radius: 5px;
//     padding: 10px 0 0;
//     background: #fff;
//     width: 100%;

//     .month-row {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       width: 100%;
//       margin-bottom: 10px;
//       padding: 0 5px;
//     }

//     .weeks {
//       border-bottom: 1px solid #ccc;
//       font-size: 0.85rem;
//       font-weight: 400;

//       .day {
//         .date.active {
//           background-color: var(--main-color-one);
//           border-radius: 50%;
//           color: #000;
//           font-weight: 600;
//         }
//       }
//     }

//     .month {
//       font-weight: bold;
//     }

//     .arrow-button {
//       cursor: pointer;
//       font-size: 20px;
//     }

//     .week-days {
//       display: grid;
//       grid-template-columns: repeat(7, 1fr);
//       width: 100%;
//       margin-bottom: 10px;
//     }

//     .day {
//       cursor: pointer;
//       padding: 10px;
//       text-align: center;
//       border: 1px solid transparent;
//     }

//     .day.active {
//       background-color: lightblue;
//     }

//     .time-slots {
//       flex-direction: column;
//       align-items: center;
//       height: 400px;
//       width: 100%;
//       overflow: hidden scroll;
//       cursor: pointer;

//     }

//     .time-slot.active {
//       background: var(--main-color-one);

//       svg {
//         color: black;
//       }
//     }

//     .day.active .time-slots {
//       display: flex;
//     }

//     .time-slot {
//       display: flex;
//       justify-content: space-between;
//       width: 100%;
//       padding: 5px 30px;
//       border-bottom: 1px solid #ccc;
//       color: ccc;
//     }

//     .time-slot:last-of-type {
//       border-bottom: unset;
//     }
//   }
// }

.date-picker {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 16px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  .calendar {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .month-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;

    .month {
      font-size: 18px;
      font-weight: 600;
      color: #333;
    }

    .arrow-button {
      background: none;
      border: none;
      cursor: pointer;
      color: #666;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      border-radius: 50%;
      transition: background 0.2s;

      &:hover {
        background: #f9cc8a;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .weeks {
    display: flex;
    gap: 8px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding-bottom: 8px;

    .day {
      flex: 1;
      min-width: 50px;
      text-align: center;
      padding: 8px;
      border-radius: 8px;
      cursor: pointer;
      transition: background 0.2s;
      scroll-snap-align: start;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:hover:not(.disabled) {
        background: #f9cc8a;
      }

      .date {
        font-size: 16px;
        font-weight: 600;
        color: #333;

        &.active {
          color: #fff;
          background: #ec8313;
          border-radius: 50%;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
        }
      }

      .day-label {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .time-slots {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 8px;
    max-height: 300px;
    overflow-y: auto;
    padding: 8px;

    .time-slot {
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 8px;
      text-align: center;
      cursor: pointer;
      transition: background 0.2s, border-color 0.2s;

      &:hover:not(.disabled) {
        background: #f9cc8a;
        border-color: #ec8313;
      }

      &.active {
        background: #ec8313;
        color: #fff;
        border-color: #ec8313;
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      .time {
        font-size: 14px;
        font-weight: 500;
      }

      .status-message {
        font-size: 12px;
        color: #ff4d4f;
      }

      .done-icon {
        margin-left: 4px;
        color: #28a745;
      }
    }
  }

  .loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }
}